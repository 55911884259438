import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Typography } from 'antd';

import { getSignInUserOemVisibleWebSelector } from '../../redux-store/selectors/signIn';
import { getDataListCurrentViewerSelector } from '../../redux-store/selectors/dataList';
import { ViewerInfo } from './components';
import DataList, { DataListPagination, DataListFilterV2 } from '../DataList';
import { EndUsersTab } from './Tabs';
import { Footer, SearchField, TopBar, Pagination } from '../../components';
import { dataListIDViewer, dataListIDEndUsersForViewer, getViewerParams } from './constants';
import { USERS_GET_ATTACHED_TO_VIEWER_END_USERS_URL, USERS_VIEWERS_URL } from '../../api/apiUrls';
import { transformResponse } from '../../utils/transformResponse';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import i18n from '../../i18n';
import { ADD_END_USERS_TO_VIEWER_MODAL_ID, openModalWindow } from '../ModalWindow/slice';

const ViewersDetails = (props) => {
  const [expanded, setExpanded] = React.useState({});
  const { [dataListIDEndUsersForViewer]: pageSettings } = useSelector(getPagesSettingsSelector);
  const { visibleWeb, viewerDataList, match } = props;

  const { viewerID } = match.params;
  const dispatch = useDispatch();
  const viewerDataListURL = `${USERS_VIEWERS_URL}/${viewerID}`;
  const connectedEndUsersUrl = `${USERS_GET_ATTACHED_TO_VIEWER_END_USERS_URL}?viewerId=${viewerID}`;
  const viewerHasLoaded = !!viewerDataList.data?._id;

  const onExpandedChange = (all) => setExpanded(all);

  const generalTabProps = {
    transformResponse,
    onExpandedChange,
    viewerID,
    expanded
  };

  const addSMUbtnHandler = () => dispatch(openModalWindow({ modalID: ADD_END_USERS_TO_VIEWER_MODAL_ID, data: null }));

  return (
    <>
      <TopBar visibleWeb={visibleWeb} showNavigation />
      <DataList
        listID={dataListIDViewer}
        listURL={viewerDataListURL}
        Component={ViewerInfo}
        params={{ ...getViewerParams }}
      />
      {viewerHasLoaded && (
        <div className="container-fluid">
          <div
            className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10"
            style={{ margin: '30px 0 10px' }}
          >
            <DataListFilterV2
              Component={SearchField}
              componentProps={{
                searchByFields: 'sm_id,email,first_name,last_name,street,city,zip,phone',
                defaultValue: pageSettings.queries.search,
                placeholder: i18n.t('searchAllTablesElements')
              }}
              listID={dataListIDEndUsersForViewer}
              listURL={connectedEndUsersUrl}
              transformResponse={transformResponse}
            />
          </div>

          <div className="d-flex justify-content-between m-4 align-items-center">
            <Typography
              variant="h5"
              className="table-details-user-nifo"
              style={{ fontWeight: 700, fontSize: '18px' }}
            >
              {i18n.t('SMUs')}
            </Typography>
            <div className="">
              <a
                className="button button-secondary"
                onClick={addSMUbtnHandler}
              >
                <span>
                  <span>{i18n.t('addSMUbtn')}</span>
                </span>
              </a>
            </div>
          </div>

          <EndUsersTab connectedEndUsersUrl={connectedEndUsersUrl} {...generalTabProps} />

          <DataListPagination
            Component={Pagination}
            listID={dataListIDEndUsersForViewer}
            defaultPageSize={10}
          />
        </div>
      )}
      <Footer />
    </>
  );
};

ViewersDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  viewerDataList: PropTypes.instanceOf(Object).isRequired,
  visibleWeb: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  viewerDataList: getDataListCurrentViewerSelector,
  visibleWeb: getSignInUserOemVisibleWebSelector
});

export default connect(mapStateToProps)(ViewersDetails);

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { TagField, DeviceNameField, DescriptionTagField, CustomSelect } from '../../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import RenderFieldsByDevName from '../components/RenderFieldsByDevName';
import { devicesGetTypesAndNames } from '../../UserDetails/slice';
import { clearSensorsOauth2FormData } from '../../SensorsOauth2/slice';
import { getSensorsConfigurationFile, saveDevicesTypesList } from '../slice';
import { additionalFields, getNamesByType, devicesInitialValues, checkShowDeviceActivity } from '../utils';
import ctaHeatPumpInitialValues from '../utils/ctaHeatPumpInitialValues';
import {
  isExpiredSensorsConfigurationFileSelector,
  addDeviceFormDevice_group,
  addDeviceFormData,
  addDeviceFormType,
  devicesTypesList
} from './selectors';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { getSensorsOauth2FormDataSelector } from '../../../redux-store/selectors/sensorsOauth2';
import { getModalsIsLoadingSelector, getModalsSelector } from '../../../redux-store/selectors/modals';
import { allDevicesTypesList, sensorsConfigurationFileSelector } from '../../../redux-store/selectors/devicesTable';
import { required } from '../../validation';
import { addDeviceFormId } from '../constants';
import { ADD_DEVICE_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';
import { useAdditionalValuesForFields } from '../hooks/useAdditionalValuesForFields';
import i18n from '../../../i18n';

/**
 * AddDeviceWindow form id
 */
const formID = addDeviceFormId;
const selectValue = formValueSelector(formID);

/**
 * Modal window to add device
 * @memberof module:UserDevices
 */
const AddDeviceWindow = (props) => {
  const {
    handleSubmit,
    randomColor,
    deviceType,
    initialize,
    dispatch,
    title = '',
    userApp,
    license,
    userId
  } = props;

  const type = useSelector(addDeviceFormType);
  const data = useSelector(addDeviceFormData);
  const modals = useSelector(getModalsSelector);
  const devicesList = useSelector(allDevicesTypesList);
  const uniqueDeviceTypes = useSelector(devicesTypesList);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const device_group = useSelector(addDeviceFormDevice_group);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const sensorsOauth2FormData = useSelector(getSensorsOauth2FormDataSelector);
  const sensorsConfigurationFile = useSelector(sensorsConfigurationFileSelector);
  const fieldNameColor = useSelector((state) => selectValue(state, 'device_color'));
  const isExpiredSensorsConfigurationFile = useSelector(isExpiredSensorsConfigurationFileSelector);

  const modal = modals?.[ADD_DEVICE_MODAL_ID];
  const newDeviceType = modal?.data?.newType || deviceType;
  const isShowDeviceActivity = newDeviceType !== 'sub-meter';

  const customFilter = useCallback((candidate, input = '') => !input || candidate?.label.toLowerCase().includes(input.toLowerCase()), []);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_DEVICE_MODAL_ID }));

  useAdditionalValuesForFields({ data, formID });

  useEffect(() => {
    if (modal?.opened && (!sensorsConfigurationFile || isExpiredSensorsConfigurationFile)) {
      dispatch(getSensorsConfigurationFile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSensorsConfigurationFile, modal?.opened]);

  useEffect(() => {
    if (!modal?.opened) {
      initialize();

      if (sensorsOauth2FormData) {
        dispatch(clearSensorsOauth2FormData());
      }
    }
    if (modal?.opened) {
      switch (newDeviceType) {
        case 'inverters':
          initialize({ type: 'Inverter' });
          break;
        case 'smart-meter':
        case 'sub-meter':
          initialize({ type: 'Smart Meter' });
          break;
        case 'input-devices':
          initialize({ type: 'Input Device' });
          break;
        default:
          if (sensorsOauth2FormData) {
            initialize(sensorsOauth2FormData);
          } else {
            initialize();
          }
          break;
      }
      dispatch(devicesGetTypesAndNames({
        deviceType: newDeviceType,
        userId,
        cb: (result) => saveDevicesTypesList({ data: result })
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened, newDeviceType, sensorsOauth2FormData, devicesGetTypesAndNames, clearSensorsOauth2FormData]);

  useEffect(() => {
    if (type && (!sensorsOauth2FormData || (sensorsOauth2FormData?.type !== type))) {
      initialize({ type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (device_group && !sensorsOauth2FormData) {
      initialize({
        type,
        device_group,
        ...devicesInitialValues[device_group],
        deviceActivity: isShowDeviceActivity ? checkShowDeviceActivity(type) : undefined
      });
    }
    // NOTE: In dependencies is missing 'type', because we need
    // reinitialize form values only if 'device_group' is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_group]);
  useEffect(() => {
    const ctaFieldValue = data?.ctaTypeRTU ?? data?.ctaType ?? data?.ctaTypeCarel;
    const sensorParameters = ctaHeatPumpInitialValues[device_group]?.find(({ index }) => index === ctaFieldValue);

    if (sensorParameters && !sensorsOauth2FormData) {
      const { values = {} } = sensorParameters;

      initialize({
        type,
        device_group,
        device_color: fieldNameColor || randomColor,
        data: { ...data, ...values },
        deviceActivity: isShowDeviceActivity ? checkShowDeviceActivity(type) : undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ctaTypeRTU, data?.ctaType, data?.ctaTypeCarel]);

  useEffect(() => {
    if (data?.batteryChargingMode === 2) {
      dispatch(change(formID, 'data.socLimit', 95));
    }

    if (data?.batteryChargingMode === 3) {
      dispatch(change(formID, 'data.socLimit', 15));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  return (
    <ModalWindow modalID={ADD_DEVICE_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{modal?.data?.newTitle || title}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              noValidate
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
              id={formID}
            >
              <div
                style={['inverters', 'smart-meter', 'sub-meter', 'input-devices'].includes(newDeviceType)
                  ? { display: 'none' } : {}}
                className="form-group m-form__group input-field zIndex21"
              >
                <div className="flags-select-label">{`${i18n.t('deviceType')} *`}</div>
                <Field
                  name="type"
                  component={CustomSelect}
                  placeholder={i18n.t('selectType')}
                  options={uniqueDeviceTypes}
                  isSearchable={false}
                  validate={[required]}
                  className="m-input"
                />
              </div>
              <DeviceNameField
                deviceName={device_group}
                newDeviceType={newDeviceType}
                randomColor={randomColor}
                form={addDeviceFormId}
                labelDeviceGroup={`${i18n.t('deviceName')} *`}
                placeholder={type ? i18n.t('selectName') : i18n.t('firstSelectType')}
                namesList={getNamesByType(devicesList, type, license?.restrictions?.allowedDeviceGroups, license?.type)}
                filterOption={customFilter}
                isSearchable
                support_url={data?.support_url}
                myRoleType={myRoleType}
              />
              {['sub-meter'].includes(newDeviceType) && (
                <>
                  <Field name="data.subMeterCostTypes" {...additionalFields.subMeterCostTypes} />
                  <br />
                </>
              )}
              <DescriptionTagField deviceType={newDeviceType} />
              <TagField
                deviceType={newDeviceType}
                formID={formID}
              />
              {['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_install', 'property_management', 'oem', 'end_user', 'oem_employee', 'viewer'].includes(myRoleType) ? (
                <RenderFieldsByDevName
                  deviceName={device_group}
                  data={data}
                  modal={modal}
                  myRoleType={myRoleType}
                  type={type}
                  formID={formID}
                  isShowDeviceActivity={isShowDeviceActivity}
                  newDeviceType={newDeviceType}
                  mode="add"
                  license={license || {}}
                  userApp={userApp}
                />
              ) : null}
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} isSubmit type="save" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

AddDeviceWindow.propTypes = {
  deviceType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  randomColor: PropTypes.string,
  userApp: PropTypes.string.isRequired,
  title: PropTypes.string,
  license: PropTypes.instanceOf(Object),
  userId: PropTypes.string
};

const form = reduxForm({
  form: formID
})(AddDeviceWindow);

export default form;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import solarLogo from './logos/logo.png';
import logoShort from './logos/logo-short.png';

import './index.scss';

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = ({ short = false }) => (
  <div className="m-login__log">
    <Link to="/">
      <img src={short ? logoShort : solarLogo} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

export default Logo;

Logo.propTypes = {
  short: PropTypes.bool
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DevicesTable from '../../../DevicesTable';
import { PrimaryButton, ZohoHoc } from '../../../../components/UIKit';
import { houseFuseSelector, loadManagementSelector } from '../../selectors';
import { openModalWindow, modalWindowSend, DATA_EXPORT_MODAL_ID, DATA_EXPORT_INFO_POPUP } from '../../../ModalWindow/slice';
import { DataExportModalWindow } from '../../../../modals';
import UserDetailsTabs from '../../UserDetailsTabs';
import { DATA_EXPORT_GATEWAY_DATA_URL } from '../../../../api/apiUrls';

import i18n from '../../../../i18n';

import '../../index.scss';
import DataExportInfoPopUp from '../../../../modals/DataExportInfoPopUp';
import { dataExportZohoLink } from '../../constants';

/**
 * This layout is rendered when page is being viewed by end_user
 * @memberof module:UserDetails
 */
const ViewByEndUsers = (props) => {
  const { myRoleType, user = {} } = props;
  const dispatch = useDispatch();

  const houseFuse = useSelector(houseFuseSelector);
  const loadManagement = useSelector(loadManagementSelector);

  // TODO: move sendEndUserDataExportDateRange func inside DataExportWindow component
  const sendEndUserDataExportDateRange = (fields) => {
    const { fromDate, toDate } = fields;
    const from = new Date(fromDate).toISOString();
    const to = new Date(toDate).toISOString();

    dispatch(openModalWindow(
      { modalID: DATA_EXPORT_INFO_POPUP, data: { descriptionLabelKey: 'dataExportInfoPopUpMessage' } }
    ));

    return dispatch(modalWindowSend({
      modalID: DATA_EXPORT_MODAL_ID,
      requestConfig: {
        method: 'post',
        url: DATA_EXPORT_GATEWAY_DATA_URL,
        data: {
          gatewayIds: [user?.gateway?._id],
          from,
          to
        }
      }
    }));
  };

  const exportDataHandler = () => dispatch(openModalWindow(
    { modalID: DATA_EXPORT_MODAL_ID }
  ));

  return (
    <div>
      <div className="settings-header-block device-top-bar">
        <div className="col-md-12">
          <div
            className="row align-items-center justify-content-between"
            style={{ height: '62px' }}
          >
            <div className="col-auto">
              <h6 className="set-prof-head">{i18n.t('SettingsSMU')}</h6>
            </div>
            <div className="col-auto">
              <Link
                to="/dashboard/"
                className="button button-secondary"
              >
                <span>{i18n.t('back')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <UserDetailsTabs user={user} myRoleType={myRoleType} />
      <div className="container-fluid">
        <div className="d-flex justify-content-end order-3 btn-sm-us export-button">
          <ZohoHoc zohoLink={dataExportZohoLink}>
            <PrimaryButton onClickHandler={exportDataHandler} i18nLabel="exportData" isLargeButton type="upload" />
          </ZohoHoc>
        </div>
      </div>
      <DevicesTable showTopBar myRoleType={myRoleType} license={user?.license} userApp={user?.userApp} />
      {user?.gateway && (
        <div className="container-fluid">
          <Link
            to="/load-management-dashboard/"
            className={`button button-secondary right dashboard-btn mr-4 ${(!houseFuse || !loadManagement) && 'dashboard-btn-disabled'}`}
          >
            {i18n.t('loadManagementBtn')}
          </Link>
        </div>
      )}
      <DataExportModalWindow
        initialValues={{
          fromDate: moment().subtract(1, 'month').startOf('day').valueOf(),
          toDate: moment().valueOf()
        }}
        onSubmit={sendEndUserDataExportDateRange}
        modalID={DATA_EXPORT_MODAL_ID}
      />
      <DataExportInfoPopUp />
    </div>
  );
};

ViewByEndUsers.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired
};

export default ViewByEndUsers;

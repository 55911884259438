import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomTextInput, CustomSelect } from '../../../../../components/ReduxFormFields';
import i18n from '../../../../../i18n';
import { Expander, Loader } from '../../../../../components';

const formID = 'additionalCommandParams';

const AdditionalCommandParamsForm = ({ pristine, commands = [], handleSubmit, isLoading }) => {
  const selector = formValueSelector(formID);
  const command = useSelector((state) => selector(state, 'command'));

  const selectedCommand = commands.find((item) => item.value === command);

  return (
    <form id={formID} onSubmit={handleSubmit}>
      <Box
        sx={{
          mt: 2,
          borderRadius: 1,
          maxWidth: 600,
          rowGap: 0.2,
          p: 3,
          position: 'relative'
        }}
        className="main-table-container commands-form"
      >
        {isLoading && <Loader />}
        <div className="list-item-title mb-4">{i18n.t('commands')}</div>
        <Field
          name="command"
          component={CustomSelect}
          label="commands"
          options={commands}
          className="m-input mt-2"
        />
        <Expander expanded={selectedCommand?.params?.some(({ name }) => name === 'HOST')}>
          <Field
            name="HOST"
            component={CustomTextInput}
            label="host"
            className="m-input w-100 mt-4"
          />
        </Expander>
        <Expander expanded={selectedCommand?.params?.some(({ name }) => name === 'PORT')}>
          <Field
            name="PORT"
            component={CustomTextInput}
            label="port.label"
            className="m-input w-100 mt-4"
          />
        </Expander>
        <Expander expanded={selectedCommand?.params?.some(({ name }) => name === 'PATH')}>
          <Field
            name="PATH"
            component={CustomTextInput}
            label="path"
            className="m-input w-100 mt-4"
          />
        </Expander>

        <Box sx={{
          pt: 2,
          textAlign: 'right'
        }}
        >
          <button
            type="submit"
            className="button button-secondary"
            disabled={pristine || isLoading}
          >
            {i18n.t('send')}
          </button>
        </Box>
      </Box>
    </form>
  );
};

export default reduxForm({
  form: formID
})(AdditionalCommandParamsForm);

AdditionalCommandParamsForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  commands: PropTypes.instanceOf(Array),
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

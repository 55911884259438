import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { Tooltip } from '../../../../components';

import { additionalFields } from '../../utils';
import i18n from '../../../../i18n';
import { setDeviceIdRtu } from '../../slice';
import { getModalsAddDeviceOpenedSelector } from '../../../../redux-store/selectors/modals';
import { addNotification } from '../../../NotificationGenerator/slice';
import { EDIT_ROW_MODAL_ID, modalStartLoading } from '../../../ModalWindow/slice';

import './styles.scss';

/**
 * Renders groupe of device_id fields
 * @function DeviceIdRTU
 */
const DeviceIdRTU = (props) => {
  const { sensorId = '', value = '' } = props;
  const dispatch = useDispatch();

  const isAddModalFormOpened = useSelector(getModalsAddDeviceOpenedSelector);

  /**
   * Check if modal that opened is 'addDeviceForm' - show error,
   * otherwise if 'editDeviceForm' - change Device_ID and Device_ID_RTU fields
   */
  const onClickSetIdHandler = () => {
    if (isAddModalFormOpened) {
      dispatch(addNotification({ type: 'error', text: i18n.t('setIdErrorMessage') }));
    }
    if (sensorId) {
      dispatch(modalStartLoading({ modalID: EDIT_ROW_MODAL_ID }));
      dispatch(setDeviceIdRtu({ sensorId, value, modalId: EDIT_ROW_MODAL_ID }));
    }
  };

  return (
    <div className="form-group m-form__group input-field device-id-rtu">
      <Field
        name="data.Device_ID_RTU"
        {...additionalFields.Device_ID_RTU}
        label={i18n.t(additionalFields.Device_ID_RTU.label)}
        showTooltip=""
        className="device-id-RTU-field"
      />
      <div className="container-of-button-and-tooltip">
        <button
          type="button"
          className="button button-secondary button-small"
          onClick={onClickSetIdHandler}
        >
          {i18n.t('Set ID')}
        </button>
        <div className="tooltip-set-id-RTU-container">
          <Tooltip title={i18n.t(additionalFields.Device_ID_RTU.showTooltip)} placement="left" />
        </div>
      </div>
    </div>
  );
};

DeviceIdRTU.propTypes = {
  value: PropTypes.number,
  sensorId: PropTypes.string
};

export default DeviceIdRTU;

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { installedSMSendRequest } from '../../../containers/InstalledSM/slice';
import {
  CONFIRM_REMOVE_GATEWAY_MODAL_ID,
  REMOVE_FIRMWARE_UPDATE_MODAL_ID,
  openModalWindow
} from '../../../containers/ModalWindow/slice';
import { ACCOUNT_REQUEST_LOG_URL } from '../../../api/apiUrls';

import i18n from '../../../i18n';

/**
 * Renders signle button for react table
 * @memberof module:ReactTable
 */
const RenderSingleButton = (props) => {
  const { row: { original }, title } = props;
  const { _id, sm_id } = original;
  const dispatch = useDispatch();

  const configObj = {
    sendLogRequest: {
      callback: () => dispatch(installedSMSendRequest({
        method: 'post',
        url: `${ACCOUNT_REQUEST_LOG_URL}/${_id}`,
        config: undefined,
        onResult: { successMessage: 'logRequestSuccessful', errorMessage: 'logRequestError' }
      })),
      buttonName: i18n.t('sendLogRequest')
    },
    removeDevice: {
      callback: () => dispatch(openModalWindow({ modalID: CONFIRM_REMOVE_GATEWAY_MODAL_ID, data: { id: _id, sm_id } })),
      buttonName: i18n.t('removeBtn')
    },
    removeFirmware: {
      callback: () => dispatch(openModalWindow({ modalID: REMOVE_FIRMWARE_UPDATE_MODAL_ID, data: { id: _id } })),
      buttonName: i18n.t('removeBtn')
    }
  };

  return (
    <a
      onClick={configObj[title].callback}
      className="button button-secondary send-log-request"
    >
      {configObj[title].buttonName}
    </a>
  );
};

RenderSingleButton.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired
};

export default RenderSingleButton;

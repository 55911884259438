import React from 'react';

export const getOrderIcon = (isSorted, direction) => {
  if (!isSorted) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
        <path d="M9.37354 11.401V1.97808L7.02051 4.23406C6.90616 4.34208 6.75496 4.40227 6.59766 4.40227C6.44035 4.40227 6.28867 4.34208 6.17432 4.23406C6.11915 4.1819 6.07545 4.11897 6.04541 4.04924C6.01537 3.97952 6 3.90446 6 3.82854C6 3.75262 6.01537 3.67744 6.04541 3.60771C6.07545 3.53799 6.11915 3.47517 6.17432 3.42302L9.55859 0.179003C9.63391 0.107175 9.72665 0.0562226 9.82764 0.0310541C9.93313 -0.00377778 10.0459 -0.00942098 10.1543 0.0146967C10.2627 0.0388143 10.3626 0.0917579 10.4434 0.168017L13.8257 3.41204C13.881 3.46411 13.925 3.52699 13.9551 3.59673C13.9852 3.66646 14.0005 3.74159 14.0005 3.81755C14.0005 3.89351 13.9852 3.96864 13.9551 4.03838C13.925 4.10811 13.881 4.17099 13.8257 4.22307C13.7113 4.33109 13.5596 4.39116 13.4023 4.39116C13.245 4.39116 13.0938 4.33109 12.9795 4.22307L10.5684 1.91008V11.402C10.5684 11.5612 10.5051 11.7138 10.3926 11.8263C10.2801 11.9389 10.1274 12.002 9.96826 12.002C9.80983 12.0007 9.65835 11.9367 9.54688 11.8241C9.4354 11.7115 9.37327 11.5595 9.37354 11.401Z" fill="black" fillOpacity="0.5" />
        <path d="M3.82707 11.969C3.72598 11.9436 3.63327 11.8922 3.55807 11.82L0.175066 8.57605C0.119793 8.52406 0.0757472 8.46131 0.0456396 8.39166C0.015532 8.322 0 8.24693 0 8.17105C0 8.09517 0.015532 8.02009 0.0456396 7.95044C0.0757472 7.88079 0.119793 7.81803 0.175066 7.76605C0.289418 7.65803 0.440762 7.59785 0.598066 7.59785C0.755369 7.59785 0.906713 7.65803 1.02107 7.76605L3.37307 10.022V0.598047C3.37307 0.519254 3.38859 0.441232 3.41874 0.368437C3.44889 0.295641 3.49309 0.229498 3.5488 0.173783C3.60452 0.118068 3.67066 0.073872 3.74346 0.0437192C3.81625 0.0135663 3.89427 -0.00195312 3.97307 -0.00195312C4.05186 -0.00195313 4.12988 0.0135663 4.20268 0.0437192C4.27547 0.073872 4.34161 0.118068 4.39733 0.173783C4.45304 0.229498 4.49724 0.295641 4.52739 0.368437C4.55755 0.441232 4.57307 0.519254 4.57307 0.598047V10.089L6.98007 7.77705C7.09442 7.66903 7.24576 7.60885 7.40307 7.60885C7.56037 7.60885 7.71171 7.66903 7.82607 7.77705C7.88134 7.82904 7.92538 7.89179 7.95549 7.96144C7.9856 8.03109 8.00113 8.10617 8.00113 8.18205C8.00113 8.25793 7.9856 8.333 7.95549 8.40266C7.92538 8.47231 7.88134 8.53506 7.82607 8.58705L4.44307 11.832C4.32908 11.9406 4.17748 12.0008 4.02007 12C3.9545 11.9999 3.88937 11.9895 3.82707 11.969Z" fill="black" fillOpacity="0.5" />
      </svg>
    );
  }

  if (direction === 'desc') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
        <path d="M9.57349 11.401V1.97801L7.22046 4.234C7.10611 4.34201 6.95491 4.40221 6.79761 4.40221C6.6403 4.40221 6.48862 4.34201 6.37427 4.234C6.3191 4.18184 6.2754 4.11891 6.24536 4.04918C6.21533 3.97946 6.19995 3.9044 6.19995 3.82848C6.19995 3.75256 6.21533 3.67738 6.24536 3.60765C6.2754 3.53793 6.3191 3.47511 6.37427 3.42296L9.75854 0.178942C9.83386 0.107114 9.9266 0.0561615 10.0276 0.030993C10.1331 -0.00383882 10.2458 -0.00948202 10.3542 0.0146356C10.4627 0.0387533 10.5625 0.0916969 10.6433 0.167956L14.0256 3.41197C14.0809 3.46405 14.1249 3.52693 14.155 3.59667C14.1851 3.6664 14.2004 3.74153 14.2004 3.81749C14.2004 3.89345 14.1851 3.96858 14.155 4.03832C14.1249 4.10805 14.0809 4.17093 14.0256 4.22301C13.9113 4.33103 13.7596 4.3911 13.6023 4.3911C13.445 4.3911 13.2938 4.33103 13.1794 4.22301L10.7683 1.91002V11.402C10.7683 11.5611 10.7051 11.7138 10.5925 11.8263C10.48 11.9388 10.3273 12.0019 10.1682 12.0019C10.0098 12.0006 9.8583 11.9367 9.74683 11.8241C9.63535 11.7115 9.57322 11.5594 9.57349 11.401Z" fill="#1F1F1F" />
        <path d="M4.02702 11.969C3.92593 11.9435 3.83322 11.8922 3.75802 11.82L0.375017 8.57599C0.319744 8.524 0.275698 8.46125 0.245591 8.39159C0.215483 8.32194 0.199951 8.24687 0.199951 8.17099C0.199951 8.09511 0.215483 8.02003 0.245591 7.95038C0.275698 7.88073 0.319744 7.81797 0.375017 7.76599C0.489369 7.65797 0.640713 7.59779 0.798017 7.59779C0.95532 7.59779 1.10666 7.65797 1.22102 7.76599L3.57302 10.022V0.597986C3.57302 0.519193 3.58854 0.441171 3.61869 0.368376C3.64884 0.29558 3.69304 0.229437 3.74875 0.173722C3.80447 0.118007 3.87061 0.073811 3.94341 0.0436581C4.0162 0.0135053 4.09422 -0.00201416 4.17302 -0.00201416C4.25181 -0.00201416 4.32983 0.0135053 4.40263 0.0436581C4.47542 0.073811 4.54157 0.118007 4.59728 0.173722C4.653 0.229437 4.69719 0.29558 4.72734 0.368376C4.7575 0.441171 4.77302 0.519193 4.77302 0.597986V10.089L7.18002 7.77699C7.29437 7.66897 7.44571 7.60879 7.60302 7.60879C7.76032 7.60879 7.91166 7.66897 8.02602 7.77699C8.08129 7.82897 8.12534 7.89173 8.15544 7.96138C8.18555 8.03103 8.20108 8.10611 8.20108 8.18199C8.20108 8.25787 8.18555 8.33294 8.15544 8.40259C8.12534 8.47225 8.08129 8.535 8.02602 8.58699L4.64302 11.832C4.52903 11.9405 4.37743 12.0008 4.22002 12C4.15445 11.9999 4.08932 11.9894 4.02702 11.969Z" fill="#1F1F1F" fillOpacity="0.5" />
      </svg>
    );
  }

  if (direction === 'asc') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
        <path d="M9.57349 11.401V1.97801L7.22046 4.234C7.10611 4.34201 6.95491 4.40221 6.79761 4.40221C6.6403 4.40221 6.48862 4.34201 6.37427 4.234C6.3191 4.18184 6.2754 4.11891 6.24536 4.04918C6.21533 3.97946 6.19995 3.9044 6.19995 3.82848C6.19995 3.75256 6.21533 3.67738 6.24536 3.60765C6.2754 3.53793 6.3191 3.47511 6.37427 3.42296L9.75854 0.178942C9.83386 0.107114 9.9266 0.0561615 10.0276 0.030993C10.1331 -0.00383882 10.2458 -0.00948202 10.3542 0.0146356C10.4627 0.0387533 10.5625 0.0916969 10.6433 0.167956L14.0256 3.41197C14.0809 3.46405 14.1249 3.52693 14.155 3.59667C14.1851 3.6664 14.2004 3.74153 14.2004 3.81749C14.2004 3.89345 14.1851 3.96858 14.155 4.03832C14.1249 4.10805 14.0809 4.17093 14.0256 4.22301C13.9113 4.33103 13.7596 4.3911 13.6023 4.3911C13.445 4.3911 13.2938 4.33103 13.1794 4.22301L10.7683 1.91002V11.402C10.7683 11.5611 10.7051 11.7138 10.5925 11.8263C10.48 11.9388 10.3273 12.0019 10.1682 12.0019C10.0098 12.0006 9.8583 11.9367 9.74683 11.8241C9.63535 11.7115 9.57322 11.5594 9.57349 11.401Z" fill="#1F1F1F" fillOpacity="0.5" />
        <path d="M4.02702 11.969C3.92593 11.9435 3.83322 11.8922 3.75802 11.82L0.375017 8.57599C0.319744 8.524 0.275698 8.46125 0.245591 8.39159C0.215483 8.32194 0.199951 8.24687 0.199951 8.17099C0.199951 8.09511 0.215483 8.02003 0.245591 7.95038C0.275698 7.88073 0.319744 7.81797 0.375017 7.76599C0.489369 7.65797 0.640713 7.59779 0.798017 7.59779C0.95532 7.59779 1.10666 7.65797 1.22102 7.76599L3.57302 10.022V0.597986C3.57302 0.519193 3.58854 0.441171 3.61869 0.368376C3.64884 0.29558 3.69304 0.229437 3.74875 0.173722C3.80447 0.118007 3.87061 0.073811 3.94341 0.0436581C4.0162 0.0135053 4.09422 -0.00201416 4.17302 -0.00201416C4.25181 -0.00201416 4.32983 0.0135053 4.40263 0.0436581C4.47542 0.073811 4.54157 0.118007 4.59728 0.173722C4.653 0.229437 4.69719 0.29558 4.72734 0.368376C4.7575 0.441171 4.77302 0.519193 4.77302 0.597986V10.089L7.18002 7.77699C7.29437 7.66897 7.44571 7.60879 7.60302 7.60879C7.76032 7.60879 7.91166 7.66897 8.02602 7.77699C8.08129 7.82897 8.12534 7.89173 8.15544 7.96138C8.18555 8.03103 8.20108 8.10611 8.20108 8.18199C8.20108 8.25787 8.18555 8.33294 8.15544 8.40259C8.12534 8.47225 8.08129 8.535 8.02602 8.58699L4.64302 11.832C4.52903 11.9405 4.37743 12.0008 4.22002 12C4.15445 11.9999 4.08932 11.9894 4.02702 11.969Z" fill="#1F1F1F" />
      </svg>
    );
  }

  return null;
};

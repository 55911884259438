import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import i18n from '../../i18n';
import './index.scss';
import { useImportFileMutation } from './services';
import { addNotification } from '../../containers/NotificationGenerator/slice';
import { getUserDevicesUser_IdSelector } from '../../redux-store/selectors/userDevices';
import {
  DATA_EXPORT_INFO_POPUP,
  closeModalWindow,
  openModalWindow
} from '../../containers/ModalWindow/slice';

const { Dragger } = Upload;

const DataImportModal = (props) => {
  const { modalID } = props;
  const dispatch = useDispatch();
  const [filesForUpload, setFilesForUpload] = React.useState([]);
  const userDeviceId = useSelector(getUserDevicesUser_IdSelector);
  const addFiles = (files) => {
    setFilesForUpload(files);
  };

  const [importFile, { isSuccess: isSuccessImport, error: importError }] = useImportFileMutation();

  React.useEffect(() => {
    if (isSuccessImport) {
      dispatch(addNotification({ text: i18n.t('success'), type: 'success' }));
    }

    if (importError) {
      dispatch(addNotification({ text: importError?.message || i18n.t('error'), type: 'error' }));
    }
  }, [isSuccessImport, importError, dispatch]);

  const draggerProps = {
    data: null,
    multiple: false,
    name: 'file',
    accept: '.csv',
    showUploadList: false,
    customRequest: () => null,
    onChange: (info) => addFiles(info.fileList)
  };

  const resetFileHandler = () => setFilesForUpload([]);
  const closeCurrentModal = () => {
    dispatch(closeModalWindow({ modalID }));
    resetFileHandler();
  };

  const importFileHandler = () => {
    const formData = new FormData();
    formData.append('file', filesForUpload[0].originFileObj);

    importFile({ userId: userDeviceId, formData });

    closeCurrentModal();
    dispatch(
      openModalWindow({
        modalID: DATA_EXPORT_INFO_POPUP,
        data: { descriptionLabelKey: 'importStartedInBackground' }
      })
    );
  };

  return (
    <ModalWindow modalID={modalID}>
      <div className="modal-header custom-modal-header">
        <h5 className="modal-title">
          <span>{i18n.t('importData')}</span>
        </h5>
        <button type="button" className="close-btn" onClick={closeCurrentModal}>
          <i className="la la-close" />
        </button>
      </div>
      <div className="modal-body import-data-modal">
        <span>{i18n.t('importDataDesc')}</span>
        <br />
        <br />
        <span className="note">
          {i18n.t('note')}
          !
        </span>
        <span>
          {' '}
          {i18n.t('importDataNote')}
        </span>
        <div>
          {filesForUpload.length
            ? filesForUpload.map((file) => (
              <div className="file" key={file.uid}>
                <span>{file.name}</span>
                {' '}
                <button type="button" className="remove-file" onClick={resetFileHandler}>
                  <i className="la la-close" />
                </button>
              </div>
            ))
            : null}
        </div>
      </div>
      <div className="modal-footer chooseFile-area">
        {filesForUpload.length > 0 ? (
          <div>
            <button
              type="button"
              className="button button-secondary exportBtn import-file-btn"
              onClick={importFileHandler}
            >
              {i18n.t('importFile')}
            </button>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Dragger className="dragger" {...draggerProps}>
            <div>
              <button
                type="button"
                className="button button-secondary exportBtn choose-file-btn"
              >
                {i18n.t('chooseFile')}
              </button>
              <p className="drop-file-text">
                {' '}
                {i18n.t('chooseFilesSubtitle')}
              </p>
            </div>
          </Dragger>
        )}
      </div>
    </ModalWindow>
  );
};

DataImportModal.propTypes = {
  modalID: PropTypes.string.isRequired
};

export default DataImportModal;

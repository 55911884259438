const replace = {
  changeSMID: "Modifier l'ID du ABB OEM",
  reqSMID: 'ABB ID *',
  SMID: 'ABB ID',
  SMIDTolltip: 'L\'ID ABB est un identifiant unique de votre ABB. Vous trouverez cet identifiant sur l\'appareil sous le code QR.',
  enableSmId: 'ABB ID',
  smidLabel: 'ABB ID',
  confirmDeletingDesc: 'Confirmez la suppression de toutes les données de cette passerelle en entrant dans le ABB ID. Après confirmation, ce processus ne peut pas être inversé.',
  confirmDeletingError: 'L\'ID ne correspond pas à l\'ID du ABB. La passerelle n\'est pas supprimée.'
};

export default replace;

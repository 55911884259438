const replace = {
  changeSMID: 'Cambia l\'ID ABB OEM',
  reqSMID: 'ABB ID *',
  SMID: 'ABB ID',
  SMIDTolltip: 'Il ABB ID è un identificatore unico del tuo dispositivo. Questo ID si trova sul dispositivo sotto il codice QR.',
  enableSmId: 'ABB ID',
  smidLabel: 'ABB ID',
  confirmDeletingDesc: 'Conferma la cancellazione di tutti i dati di questo gateway inserendo l\'ID ABB. Dopo la conferma, questo processo non può essere invertito.',
  confirmDeletingError: 'L\'ID non corrisponde all\'ID ABB. Il gateway non è stato cancellato.'
};

export default replace;

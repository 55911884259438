import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

export const CustomFormFieldButton = (props) => {
  const {
    label,
    meta: { touched, error },
    languageToSync = '',
    isShowCheckmk = false,
    onClick,
    disabled
  } = props;

  const localeLabel = i18n.exists(label) ? i18n.t(label) : label;

  return (
    <>
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className="button button-secondary button-small"
      >
        {isShowCheckmk && <i className="la la-check mr-2 connected-auth-check-icon" />}
        {localeLabel}
      </button>
      {
        touched && (error || languageToSync) && (
          <div className="inputCustomWarning">
            {i18n.t(error)}
          </div>
        )
      }
    </>
  );
};

CustomFormFieldButton.propTypes = {
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  languageToSync: PropTypes.string,
  isShowCheckmk: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { openModalWindow, SKI_SEARCH_MODAL_ID } from '../../../containers/ModalWindow/slice';
import { getUserDevicesUserSelector } from '../../../redux-store/selectors/userDevices';
import { getSignInUserRoleTypeSelector, getSignInUserSelector } from '../../../redux-store/selectors/signIn';
import { useSkiSearchMutation } from '../../../containers/DevicesTable/services';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import './index.scss';

/**
 * Renders text input for redux form
 * @memberof module:ReduxFormFields
 */
export const SkiSearchField = (props) => {
  const {
    input,
    label,
    showTooltip = '',
    placement,
    meta: { touched, error, form },
    preventAuto,
    className,
    defaultValue,
    languageToSync = '',
    isRequired,
    ...custom
  } = props;
  const myInputRef = useRef();
  const dispatch = useDispatch();

  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const user = useSelector(getUserDevicesUserSelector);
  const signInUser = useSelector(getSignInUserSelector);
  const gatewayId = myRoleType === 'end_user' ? signInUser.gateway._id : user.gateway._id;
  const [
    skiSearch,
    {
      data: skiSearchData,
      isLoading
    }
  ] = useSkiSearchMutation();

  const skiSearchHandler = () => {
    skiSearch({ gatewayId });
    dispatch(openModalWindow({ modalID: SKI_SEARCH_MODAL_ID, data: { skiSearchData, form, isLoading } }));
  };

  const labelProps = {
    className: `inputLabel${isRequired ? ' required' : ''}`
  };

  return (
    <div className="skiField">
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <TextField
        variant="standard"
        inputRef={myInputRef}
        label={i18n.exists(label) ? i18n.t(label) : label}
        defaultValue={defaultValue}
        {...input}
        {...custom}
        classes={{
          root: `customTextField ${className}`
        }}
        InputProps={{
          classes: {
            root: 'inputRoot',
            input: 'inputField'
          }
        }}
        inputProps={
          preventAuto
            ? {
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'off'
            }
            : {}
        }
        InputLabelProps={labelProps}
      />
      {touched && (error || languageToSync) && (
        <div className="inputCustomWarning">
          {i18n.t(error)}
        </div>
      )}
      <button
        className="button button-secondary button-small searchChargersBtn"
        type="button"
        onClick={skiSearchHandler}
      >
        {i18n.t('search')}
      </button>
    </div>
  );
};

SkiSearchField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  placement: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.instanceOf(Object)
  ]).isRequired,
  showTooltip: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  languageToSync: PropTypes.string,
  preventAuto: PropTypes.bool,
  isRequired: PropTypes.bool
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../../../ModalWindow';
import { ADD_CAR_MODAL, EDIT_CAR_FORM_ID, EDIT_CAR_MODAL } from '../constants';
import i18n from '../../../../../i18n';
import { CancelButton, PrimaryButton } from '../../../../../components/UIKit';
import { CustomTextField, CustomTextInput } from '../../../../../components/ReduxFormFields';
import { required } from '../../../../validation';

import './index.scss';
import { getModalEditCar } from '../../../../../redux-store/selectors/modals';
import { dataListReloadData } from '../../../../DataList/slice';
import { closeModalWindow, modalWindowSend, openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../../ModalWindow/slice';
import {
  getSignInUserRoleTypeSelector,
  getSignInUser_IdSelector
} from '../../../../../redux-store/selectors/signIn';
import { getUserDevicesUser_IdSelector } from '../../../../../redux-store/selectors/userDevices';
import { Tooltip } from '../../../../../components';
import { additionalFields } from '../../../utils';
import { useGetCarBrandsListQuery } from '../../../services';
import { getUserLicenseSelector } from '../../../selectors';

const EditCar = ({ initialize }) => {
  const dispatch = useDispatch();
  const { data: carBrandsList } = useGetCarBrandsListQuery();
  const modal = useSelector(getModalEditCar);
  const signInUserId = useSelector(getSignInUser_IdSelector);
  const userDeviceId = useSelector(getUserDevicesUser_IdSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const userId = myRoleType === 'end_user' ? signInUserId : userDeviceId;
  const editCarForm = useSelector(getFormValues(EDIT_CAR_FORM_ID));
  const { _id, name, data, deviceActivity, notes, vin, brand } = modal?.data || {};

  const userLicense = useSelector(getUserLicenseSelector);

  useEffect(() => {
    if (modal?.opened) {
      initialize({ name, data, deviceActivity: deviceActivity || 0, notes: notes || '' });
    } else {
      initialize({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const onClose = () => {
    dispatch(closeModalWindow({ modalID: EDIT_CAR_MODAL }));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (userLicense?.restrictions?.isCarRestricted) {
      dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));

      return;
    }

    const submitData = {
      name: editCarForm.name,
      device_type: 'car',
      type: 'Car',
      device_group: 'Device Link Car',
      data: editCarForm.data,
      deviceActivity: editCarForm.deviceActivity,
      notes: editCarForm.notes
    };

    const url = myRoleType === 'end_user' ? `/sensors/car/my/${_id}` : `/sensors/car/${userId}/${_id}`;

    dispatch(
      modalWindowSend({
        modalID: EDIT_CAR_MODAL,
        requestConfig: {
          method: 'put',
          url,
          data: submitData
        },
        cb: () => dataListReloadData({ listID: 'cars' })
      })
    );

    onClose();
  };

  const relinkCarHandler = () => {
    if (userLicense?.restrictions?.isCarRestricted) {
      dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));

      return;
    }

    const selectedBrand = carBrandsList.find((car) => car.value === brand);

    onClose();
    dispatch(
      openModalWindow({
        modalID: ADD_CAR_MODAL,
        data: {
          activeTab: 1,
          sensorId: _id,
          vin,
          name,
          data,
          deviceActivity,
          notes,
          selectedBrand
        }
      })
    );
  };

  return (
    <ModalWindow modalID={EDIT_CAR_MODAL}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editCar')}</h5>
      </div>
      <div className="modal-body">
        <form id={EDIT_CAR_FORM_ID} className="edit-car-form" onSubmit={submitHandler}>
          <button
            type="button"
            className="button button-secondary button-small relink-car-btn"
            onClick={relinkCarHandler}
          >
            {i18n.t('relinkCar')}
          </button>
          <Field
            label={i18n.t('carName')}
            component={CustomTextInput}
            className="m-input mb-3"
            validate={[required]}
            name="name"
            required
          />
          <Field
            {...additionalFields.batteryCapacity}
            label={i18n.t('batteryCapacity.label')}
            name="data.batteryCapacity"
          />
          <div className="select-wrapper">
            <Field {...additionalFields.deviceActivity} name="deviceActivity" />
          </div>
          <div className="flags-select-label">
            {`${i18n.t('notes.label')}`}
            <div className="switch-tooltip tooltipInSelect">
              <Tooltip title={i18n.t('notes.tooltip')} placement="right" />
            </div>
          </div>
          <Field
            component={CustomTextField}
            className="note-field"
            placeholder={i18n.t('notes.label')}
            name="notes"
          />
        </form>
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={onClose} i18nLabel="cancel" />
        <PrimaryButton form={EDIT_CAR_FORM_ID} isSubmit type="save" />
      </div>
    </ModalWindow>
  );
};

EditCar.propTypes = {
  initialize: PropTypes.func.isRequired
};

const form = reduxForm({
  form: EDIT_CAR_FORM_ID
})(EditCar);

export default form;

const replace = {
  changeSMID: 'ABB ID ändern',
  reqSMID: 'ABB ID *',
  SMID: 'ABB ID',
  SMIDTolltip: 'Die ABB ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'ABB ID',
  smidLabel: 'ABB ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die ABB -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der ABB-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;

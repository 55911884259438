import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import _ from 'lodash';
import { addNotification } from '../../../../NotificationGenerator/slice';
import { getColumns } from './columns';
import { ItemsList } from '../ItemsList';
import AdditionalCommandParamsForm from './AdditionalCommandParamsForm';

import i18n from '../../../../../i18n';

import { useGetShellhubDataQuery, useSendIOTCommandMutation } from '../../../services';

const SupportTab = ({ user, myRoleType }) => {
  const { gateway = {} } = user || {};

  const { data = {}, isLoading, error: shellhubError } = useGetShellhubDataQuery(
    { supportId: gateway.supportId },
    { skip: !gateway.supportId || !['root', 'solar_admin'].includes(myRoleType) }
  );

  const [sendIOTCommand, { isLoading: isSending, error, data: commandData }] = useSendIOTCommandMutation();
  const [output, setOutput] = useState('');

  const dispatch = useDispatch();

  const commandsList = useMemo(() => data?.commandsV2?.map((command) => ({
    ...command,
    value: crypto?.randomUUID?.(),
    label: command.title,
    type: command.type
  })) || [], [data]);

  const sendCommand = ({ command, ...params }) => {
    const commandObj = commandsList.find(({ value }) => value === command);

    if (!commandObj) {
      return;
    }

    const filteredParams = _.pick(params, commandObj.params?.map(({ name }) => name));
    const filteredParamsArr = Object.entries(filteredParams).map(([name, value]) => ({ name, value }));

    sendIOTCommand({
      data: {
        supportId: gateway.supportId,
        hardware: gateway.hardware,
        commandId: commandObj.type,
        params: filteredParamsArr
      }
    });
  };

  const columns = useMemo(() => getColumns({
    ...data,
    isCommandsLoading: isLoading || isSending
  }), [gateway, data, isLoading, isSending]);// eslint-disable-line

  useEffect(() => {
    if (commandData) {
      const formattedResponse = commandData?.response?.replace(/\\n/g, '\n') || '';

      const updatedOutput = `${output}\n${formattedResponse || ''}`;
      setOutput(updatedOutput);
    }
  }, [commandData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    [error, shellhubError].forEach((err) => {
      if (err) {
        dispatch(addNotification({ type: 'error', text: err?.message || 'Error sending command' }));
      }
    });
  }, [dispatch, error, shellhubError]);

  if (!gateway.supportId) {
    return (
      <Box className="main-info position-relative" sx={{ p: 4, pr: 10 }}>
        <span className="list-item-title">
          {i18n.t('gatewayWithoutSupportIdWarning')}
        </span>
      </Box>
    );
  }

  return (
    <Box className="main-info position-relative" sx={{ p: 4, pr: 10 }}>
      <Box className="software-container" sx={{ display: 'inline-flex', gap: 2 }}>
        <Box sx={{ minWidth: 450 }}>
          <ItemsList columns={columns} />
          <AdditionalCommandParamsForm
            commands={commandsList}
            isLoading={isLoading || isSending}
            onSubmit={sendCommand}
            initialValues={{
              HOST: gateway?.ip || '',
              PORT: '502',
              PATH: '/'
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <textarea
            className="input-note command-response m-0 mt-2 w-100 p-2"
            style={{ minWidth: 450, height: '100%' }}
            disabled
            value={output}
          />
        </Box>
      </Box>
    </Box>
  );
};

SupportTab.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired
};

export default SupportTab;

import React from 'react';
import { Field } from 'redux-form';
import { PuffLoader } from 'react-spinners';
import { EZEE_APP_ID, APP_ID } from '../../../../../config';
import { formatDateTime } from '../../../../../utils';
import { Tooltip } from '../../../../../components';
import { Checkbox } from '../../../../../components/ReduxFormFields';
import { EMPLOYEE_ROLE_TYPES } from '../../../constants';

import { getUserInfo } from '../../../slice';

import {
  RenderSwitcher,
  RenderRebootDropdown
} from '../../../../../components/ReactTable/renderFunctions';

import i18n from '../../../../../i18n';
import { READ_ONLY_ROLES } from '../../../../../constants';

function isVersionLower(version1, version2) {
  const [major1 = 0, minor1 = 0, patch1 = 0] = version1?.split('.').map(Number) || [];
  const [major2 = 0, minor2 = 0, patch2 = 0] = version2?.split('.').map(Number) || [];

  if (major1 < major2) return true;
  if (major1 > major2) return false;

  // If major versions are equal, check minor versions
  if (minor1 < minor2) return true;
  if (minor1 > minor2) return false;

  // If minor versions are equal, check patch versions
  return patch1 < patch2;
}

const allowedRolesToUpdateSupportContract = [
  'root',
  'solar_admin',
  'pv_installer',
  'pv_installer_employee',
  'oem',
  'oem_employee',
  'property_management'
];

export const getColumns = ({
  user = {},
  myRoleType,
  handleSupportContractsChange,
  isSupportContractFlagAllowedToBeChanged,
  handleInstallationFinishedChange,
  handleUpdateUserRentedFlag,
  onStartSoftwareUpdate,
  isStartSoftwareUpdate,
  isReadOnlyPermission
}) => {
  const { gateway = {}, _id } = user;
  const isReadOnlyRole = READ_ONLY_ROLES.includes(myRoleType);

  const allowedRolesToUpdateInstallationCompleted = [
    'root',
    'solar_admin',
    'oem',
    'oem_employee',
    'pv_installer',
    'pv_installer_employee',
    'property_management',
    'end_user'
  ].includes(myRoleType);
  const allowedRolesToDisplayOptions = [
    'root',
    'solar_admin',
    'pv_installer',
    ...EMPLOYEE_ROLE_TYPES,
    'oem',
    'oem_employee',
    'oem_employee_read_only',
    'property_management',
    'viewer'
  ].includes(myRoleType);
  const onlyRebootAllowedRoles = [
    'pv_installer',
    ...EMPLOYEE_ROLE_TYPES,
    'oem',
    'oem_employee',
    'oem_employee_read_only'
  ].includes(myRoleType);
  const adminRoles = ['root', 'solar_admin'].includes(myRoleType);

  return [
    [
      {
        title: 'hardware',
        isAllowed: true,
        Value: () => <p>{gateway.hardware}</p>
      },
      {
        title: 'releaseGroup',
        isAllowed: adminRoles,
        Value: () => gateway.releaseGroup || ''
      },
      {
        title: 'SMversion',
        isAllowed: true,
        Value: () => <p>{gateway.firmware}</p>
      },
      {
        title: 'availableSoftwareVersion',
        isAllowed: allowedRolesToUpdateInstallationCompleted || ['pv_installer_employee_read_install'].includes(myRoleType),
        Value: () => {
          const isAvailableVersionLower = !isVersionLower(
            gateway.firmware,
            gateway.availableSoftwareVersion
          );

          return (
            <div className="d-flex">
              <p>{gateway.availableSoftwareVersion}</p>
              {' '}
              {!isReadOnlyPermission && (
                <button
                  type="button"
                  className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst software-update-btn d-flex align-items-center gap-1"
                  disabled={
                    gateway.availableSoftwareVersion === gateway.firmware
                    || isStartSoftwareUpdate
                    || isAvailableVersionLower
                  }
                  onClick={onStartSoftwareUpdate}
                >
                  {isStartSoftwareUpdate && <PuffLoader size={20} color="#FF0011" speedMultiplier={3} />}
                  <span>{i18n.t('startSoftwareUpdate')}</span>
                </button>
              )}
            </div>
          );
        }
      },
      {
        title: 'softwareInstallationDate',
        isAllowed: gateway.firmware_update_date,
        Value: () => <p>{formatDateTime(gateway.firmware_update_date)}</p>
      },
      {
        title: 'supportId',
        isAllowed: ['root', 'solar_admin'].includes(myRoleType),
        Value: () => <p style={{ textTransform: 'none' }}>{gateway?.supportId}</p>
      },
      {
        title: 'createdAt',
        isAllowed: true,
        Value: () => <p>{formatDateTime(user.createdAt)}</p>
      }
    ],
    [
      {
        isAllowed: allowedRolesToDisplayOptions,
        Value: () => (
          <div className="support-contracts-container m-0">
            <div>
              <div className="d-flex align-items-baseline support-contracts">
                <Field
                  name="support_contracts"
                  type="checkbox"
                  component={Checkbox}
                  label={i18n.t('supportContracts')}
                  labelClass="support-contracts"
                  onChange={handleSupportContractsChange}
                  disabled={
                    !allowedRolesToUpdateSupportContract.includes(myRoleType)
                    || !isSupportContractFlagAllowedToBeChanged || isReadOnlyPermission
                  }
                />
                <div>
                  <Tooltip title={i18n.t('supportContractsTooltip')} />
                </div>
              </div>
              {!isSupportContractFlagAllowedToBeChanged && (
                <span className="support-contracts-info-text m-0">
                  {i18n.t('descriptionForDisabledSupportContract')}
                </span>
              )}
            </div>
          </div>
        )
      },
      {
        isAllowed: allowedRolesToDisplayOptions && [EZEE_APP_ID].includes(APP_ID),
        Value: () => (
          <div className="support-contracts-container m-0">
            <div>
              <div className="d-flex align-items-baseline support-contracts">
                <Field
                  name="rentedPVSystemFlag"
                  type="checkbox"
                  component={Checkbox}
                  label={i18n.t('rentedSystem')}
                  labelClass="support-contracts"
                  onChange={handleUpdateUserRentedFlag}
                  disabled={!allowedRolesToUpdateInstallationCompleted || isReadOnlyPermission}
                />
                <div>
                  <Tooltip title={i18n.t('rentedSystemTooltip')} />
                </div>
              </div>
            </div>
          </div>
        )
      },
      {
        isAllowed: allowedRolesToDisplayOptions,
        Value: () => (
          <div className="support-contracts-container m-0">
            <div>
              <div className="d-flex align-items-baseline support-contracts">
                <Field
                  name="isInstallationCompleted"
                  type="checkbox"
                  component={Checkbox}
                  label={i18n.t('installationFinished')}
                  labelClass="support-contracts"
                  onChange={handleInstallationFinishedChange}
                  disabled={!allowedRolesToUpdateInstallationCompleted || isReadOnlyPermission}
                />
                <div>
                  <Tooltip title={i18n.t('installationStageTooltip')} />
                </div>
              </div>
            </div>
          </div>
        )
      },
      {
        isAllowed: adminRoles,
        wrapperClasses: 'align-self-center',
        Value: () => (
          <div className="d-flex justify-content-between align-items-center pt-2 list-item-title">
            {i18n.t('debugLogging')}
            <div style={{ width: 125 }}>
              <RenderSwitcher
                sizes={{ height: 20, width: 40 }}
                original={gateway}
                cb={() => getUserInfo({ userId: _id })}
              />
            </div>
          </div>
        )
      },
      {
        isAllowed: adminRoles || onlyRebootAllowedRoles,
        Value: () => (
          <div className="d-flex justify-content-between align-items-center list-item-title">
            {i18n.t('rebootRequest')}
            <RenderRebootDropdown
              original={gateway}
              isOnlyReboot={onlyRebootAllowedRoles}
              disabled={isReadOnlyRole || isReadOnlyPermission}
            />
          </div>
        )
      }
    ]
  ];
};

import React from 'react';
import { Checkbox } from '@mui/material';

export const getCheckbox = ({ indeterminate, className, ...rest }) => (
  <Checkbox
    icon={(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.3529 3H5.64706C4.18513 3 3 4.18513 3 5.64706V18.3529C3 19.8149 4.18513 21 5.64706 21H18.3529C19.8149 21 21 19.8149 21 18.3529V5.64706C21 4.18513 19.8149 3 18.3529 3Z" stroke="#1F1F1F" strokeOpacity="0.25" strokeWidth="2" />
      </svg>
    )}
    checkedIcon={(
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.3529 3H5.64706C4.18513 3 3 4.18513 3 5.64706V18.3529C3 19.8149 4.18513 21 5.64706 21H18.3529C19.8149 21 21 19.8149 21 18.3529V5.64706C21 4.18513 19.8149 3 18.3529 3Z" fill="#1F1F1F" />
        <path d="M8.58602 16.2584L5.75702 13.4294L7.17202 12.0164L10 14.8444L17.071 7.77344L18.485 9.18744L11.414 16.2584L10 17.6734L8.58602 16.2584Z" fill="white" />
      </svg>
    )}
    className={className}
    indeterminate={indeterminate}
    {...rest}
  />

);

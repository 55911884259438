export const DEFAULT_FONT = 'ABBvoice, sans-serif';

export const INSTALLATION_ONGOING_COLOR = '#3998F9';
export const NOT_CONNECTED_STATUS_COLOR = '#F53920';
export const CONNECTED_COLOR = '#60CD27';

export const OK_COLOR = '#1BBC9B';
export const WARNING_COLOR = '#FFB822';
export const ERROR_COLOR = '#ED6200';
export const NOT_CONNECTED_MONITORING_COLOR = '#F53920';

export const PIE_CHART_COLORS = {
  countColor: '#1F1F1F',
  itemHiddenStyle: '#525252',
  itemHoverStyle: '#525252',
  itemStyleColor: '#525252',
  titleColor: '#1F1F1F',
  bgColor: 'transparent'
};

export const CIRCLE_CHART_COLORS = {
  itemHiddenStyle: '#525252',
  itemHoverStyle: '#525252',
  itemStyleColor: '#525252',
  titleColor: '#1F1F1F',
  bgColor: 'transparent',
  OVERALL_ACTIVE_COLOR: '#DB422D',
  ONE_YEAR_ACTIVE_COLOR: '#3998F9',
  ONE_MONTH_ACTIVE_COLOR: '#4CCD37',
  OVERALL_EMPTY_COLOR: '#db422d1a',
  ONE_YEAR_EMPTY_COLOR: '#3998F91a',
  ONE_MONTH_EMPTY_COLOR: '#4CCD371a'
};

export const tooltip = {
  backgroundColor: '#fff',
  style: {
    color: '#525252',
    fontSize: '16px'
  }
};
